<template>
    <div class="create_video_container">
        <van-nav-bar class="header"
                title="编辑视频"
                left-arrow
                fixed
                safe-area-inset-top
                @click-left="handleBack"
        >
        </van-nav-bar>
        <div class="placeHolder"></div>
        <van-form validate-first :show-error-message="true" :show-error="false" @failed="onFailed" @submit="handleEditVideo(videoDetail)">
        <div class="content_container">
            <div style="padding: 20 0;word-wrap:break-word;" v-if="parseUrl">
              <span><i>*&nbsp;</i>视频地址：<span style="font-size: 14px;display: inline;">{{parseUrl}}</span></span>
            </div>
            <span><i>*&nbsp;</i>视频名称</span>
            <van-field
                    v-model="videoDetail.name"
                    rows="1"
                    autosize
                    type="textarea"
                    placeholder="输入视频名称"
                    show-word-limit
                    name="videoName"
                    :rules="[{ required: true, message: '请输入视频名称' }]"
            />

            <span><i>*</i>上传视频</span>
            <van-field v-if="!loading"
                    :rules="[{ required: true, message: '请上传视频' }]"
                    name="uploader" >
                <template #input>
                    <video-upload
                        :isReprint="isReprint"
                        :busiId="busi_Id"
                        :busiCat="busi_Cat"
                        :groupId="group_Id_context"
                        v-model="videoUploaded"
                    ></video-upload>
                </template>
            </van-field>

            <span>视频封面</span>
            <div>
                <image-upload v-if="!loading"
                        :busiId="busi_Id"
                        :busiCat="busi_Cat"
                        :groupId="group_Id_index"
                ></image-upload>
            </div>
          <span>海报图</span>
          <div class="article_img">
            <image-upload
                v-if="!loading"
                :busiId="busi_Id"
                :busiCat="`poster`"
                :groupId="`sharePosterPic`"
            ></image-upload>
          </div>
            <span>分享描述</span>
            <van-field
                    v-model="videoDetail.shareDescription"
                    rows="3"
                    autosize
                    type="textarea"
                    show-word-limit
            />

            <div class="extend_info">
                <van-field
                        label="点赞数"
                        placeholder="请输入"
                        type="number"
                        clearable
                        v-model="videoDetail.originalLikes"
                />
                <div class="custom-van-cell">
                    <div class="custom-label">在我的名片显示</div>
                    <van-switch
                            v-model="videoDetail.hasUserCardShow"
                            inactive-color="#bfbfbf"
                            size=" 0.42667rem" />
                </div>

                <van-field
                        v-show="videoDetail.hasUserCardShow"
                        v-model="videoDetail.userCardSortNum"
                        type="digit"
                        label="名片排序"
                        placeholder="数字越大越靠前"
                />
            </div>
        </div>

        <div class="footer">
            <van-grid clickable :column-num="2" safe-area-inset-bottom>
                <van-grid-item
                ><van-button
                        round
                        type="primary"
                        block
                        color="#BFBFBF"
                        @click="handleBack"
                >取消</van-button
                ></van-grid-item
                >
                <van-grid-item
                ><van-button
                        round
                        type="primary"
                        block
                        color="#FF6E01"
                        native-type="submit"
                >确定</van-button
                ></van-grid-item
                >
            </van-grid>
        </div>
        </van-form>
    </div>
</template>

<script>
    import { reactive, toRefs } from "vue";
    import { useRouter, useRoute } from "vue-router";
    import ImageUpload from "../../components/FileUpload/ImageUpload.vue";
    import VideoUpload from "../../components/FileUpload/VideoUpload.vue";
    import {editVideo, getVideoDetailForEdit} from "../../api/video";
    import { Toast } from "vant";

    export  default {
        components: {
            ImageUpload,
            VideoUpload
        },

        setup() {
            const router = useRouter()
            const route = useRoute()
            let originalVdId = route.params.vdId
            const state = reactive({
                isReprint:false,
                parseUrl:'',
                loading: true,
                busi_Id: 0,
                busi_Cat: "video",
                group_Id_index: "videoIndexPic",
                group_Id_context: "videoContentPic",
                videoDetail: {
                    vdId: "", //视频id
                    name: "", //视频名称
                    shareDescription: "", //分享描述 m
                    originalLikes: null, //初始点赞数
                    hasUserCardShow: false, //是否在名片显示
                    userCardSortNum: null //名片排序
                },
                videoUploaded: false
            });

            //获取原视频信息
            const getOriginalVideoDetailForEdit = async (vdId) => {
              state.loading = true;
              Toast.loading({
                    message: "加载中...",
                    // forbidClick: true,
                    duration: 20000,
                    loadingType: "spinner",
                });
                let params = {vdId: vdId}
                let data = await getVideoDetailForEdit(params);
                state.parseUrl = data.parseUrl
                if (data.parseUrl){
                  state.isReprint = true
                }
                state.videoDetail = data
                state.busi_Id = state.videoDetail.vdId
                console.log("===state.busi_Id===", state.busi_Id)
                state.loading = false;
                Toast.clear();

            };
            getOriginalVideoDetailForEdit(originalVdId);

            //绑定回退事件
            const handleBack = () => {
                router.back();
              // router.replace({
              //   path: `/video/videoDetail/${state.busi_Id}`
              // });
            };

            //创建视频
            const handleEditVideo = async (params) => {
                Toast.loading({
                    message: "正在保存...",
                    forbidClick: true,
                    duration: 20000,
                    loadingType: "spinner",
                });
                try {
                    let result = await editVideo(params);
                    if (result) {
                        Toast.clear();
                        router.replace({
                            path: `/video/videoDetail/${state.busi_Id}`
                        });
                    } else {
                        Toast.clear();
                        Toast.fail("保存失败,请稍后再试")
                    }
                } catch(err) {
                    Toast.clear();
                    Toast.fail("保存失败,请稍后再试")
                    console.log(err)
                }
            };

            const onFailed = errorInfo =>  {
                console.log('failed', errorInfo);
            };

            return {
                handleBack,
                handleEditVideo,
                onFailed,
                ...toRefs(state)
            }
        }

    }

</script>

<style lang="scss">
    .create_video_container {
        min-height: 100vh;
        background-color: #f1f3f6;

        .header {
            .van-nav-bar__arrow {
                color: #333333;
            }

            .van-nav-bar__title {
                color: #333333;
                font-weight: 600;
            }
        }

        .placeHolder {
            height: 46px;
            margin-top: constant(safe-area-inset-top);
            margin-top: env(safe-area-inset-top);
        }


        .content_container {
            padding: 17px;
            text-align: left;
            border-radius: 5px;

            span {
                color: rgba(0, 0, 0, 0.84);
                font-size: 16px;
                font-weight: 400;
                display: block;
                margin-bottom: 7px;
                margin-top: 20px;
                i {
                    color: red;
                }
            }

            span:first-of-type {
                margin-top: 0px;
            }

            .van-field {
                border-radius: 4px;
            }

            .extend_info {
                .van-cell {
                    margin-top: 8px;

                    span {
                        color: rgba(0, 0, 0, 0.84);
                        font-size: 0.42667rem;
                        font-weight: 400;
                        display: block;
                        margin-bottom : 0;
                        margin-top: 0
                    }
                }

                .custom-van-cell {
                    margin-top: 8px;
                    position: relative;
                    display: -webkit-box;
                    display: -webkit-flex;
                    display: -ms-flexbox;
                    display: flex;
                    box-sizing: border-box;
                    width: 100%;
                    padding: 0.26667rem 0.42667rem;
                    overflow: hidden;
                    color: #323233;
                    font-size: 0.37333rem;
                    line-height: 0.64rem;
                    background-color: #fff;
                    justify-content: space-between;
                    border-radius: 4px;

                    .custom-label {
                        color: rgba(0, 0, 0, 0.84);
                        font-size: 0.42667rem;
                        font-weight: 400;
                        display: block;
                        margin-bottom: 0;
                        margin-top: 0;
                    }
                }
            }
        }

        .footer {
            .van-button--round {
                border-radius: 8px;
                height: 44px;
                width: 160px;
            }
            .van-hairline {
                background-color: #f1f3f6;
            }
        }
    }
</style>
