<template>
  <div class="my-upload">
    <van-uploader v-show="!uploaded"
                  v-model="fileList"
                  :after-read="afterRead"
                  :max-count="1"
                  :before-read="beforeRead"
                  :before-delete="beforeDelete"
                  accept="video/mp4"
    >
      <template #default>
        <div  class="upload-box">
          <div class="upload-icon-box">
            <van-icon name="plus" style="color: white" />
          </div>
          <div class="place-msg">{{ msg }}</div>
        </div>
      </template>
    </van-uploader>
    <div v-show="uploaded" class="preview-box">
      <img v-bind:src="previewUrl"/>
      <div class="preview-icon-box-container">
        <div class="preview-icon-box">
          <van-icon name="play-circle-o" v-on:click="previewVideo" style="color: white" />
        </div>
        <div class="preview-icon-box" v-if="!isReprint">
          <van-icon  name="delete" v-on:click="throttleCustomDelete" style="color: white" />
        </div>
      </div>
    </div>
  </div>
  <van-overlay v-show="showOverlay" :show="overlayShow" @click="stopPreviewVideo">
    <div class="overlay-wrapper" >
      <video
              class="video_box"
              loop
              preload="auto"
              playsinline
              webkit-playsinline="true"
              x5-video-player-type="h5-page"
              x5-video-player-fullscreen="true"
              x5-playsinline
              x-webkit-airplay="allow"
              x5-video-orientation="portrait"
              v-bind:src="videoUrl"
              ref="videoRef"
              />
    </div>
  </van-overlay>
</template>
<script>
  import { onMounted, reactive, toRefs, ref } from "vue";
  import {  searchFileList, deleteFile, uploadFile } from "@/api/file/index";
  import { throttle } from "@/utils/commonUtil";
  import { Toast } from 'vant';

  export default {
    props: {
      busiId: {
        type: [Number, String],
      },
      newBusiId: {
        type: [String, Number],
      },
      busiCat: {
        type: String,
        default: "",
      },
      groupId: {
        type: String,
        defalut: "",
      },
      isReprint:{
        type:Boolean,
        default:false,
      },
      isRealDeleteImg: {
        type: Boolean,
        default: true,
      },
      msg: {
        type: String,
        default: ""
      },
      modelValue: {
        type: Boolean,
        default: false
      }
    },
    setup(props, context) {
      const state = reactive({
        fileList: [],
        currentFileId: 0,
        fileId: "",
        file: null,
        uploaded: false,
        previewUrl:"/img/png/video-default-cover.png",
        msg: props.msg,
        showOverlay: false,
        overlayShow: true,
        videoUrl:""
      });

      //通过ref获取van-tabs
      const videoRef = ref();


      const afterRead = (file) => {
        state.file = file.file;
        file.status = "uploading";
        file.message = "上传中...";
        let formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
        formData.append("file", file.file); //接口需要传的参数
        if (props.isRealDeleteImg) {
          formData.append("busiId", props.busiId); //接口需要传的参数
        } else {
          formData.append("busiId", props.newBusiId); //接口需要传的参数
        }
        formData.append("busiCat", props.busiCat); //接口需要传的参数
        formData.append("groupId", props.groupId); //接口需要传的参数
        uploadFile(formData)
                .then((data) => {
                  let item = {
                    url: `${process.env.VUE_APP_WATCH_FILE_API}/${data.url}`,
                    fileId: data.fileId,
                  };
                  state.fileList.pop();
                  state.fileList.push(item);
                  console.log(state.fileList);
                  state.previewUrl = "/img/png/video-default-cover.png"
                  state.uploaded = true
                  //生成视频预览url
                  state.videoUrl = window.URL.createObjectURL(state.file)
                  context.emit("input", true)
                })
                .catch((err) => {
                  console.log(err);
                  state.fileList.pop();
                  file.status = "failed";
                  file.message = "上传失败";
                  if (err.message.indexOf("timeout") != -1 && err.message.indexOf("exceeded") != -1) {
                    Toast.fail("上传超时,请尝试减小上传文件,或者检查网络")
                  } else {
                    Toast.fail(err.message)
                  }
                });
      };
      const beforeRead = (file) => {
        if (file.type !== 'video/mp4' && file.type !== 'video/quicktime') {
          Toast('请上传 mp4 格式视频');
          return false;
        }
        return true;
      };
      const beforeDelete = (file) => {
        if (file.fileId) {
          if (props.isRealDeleteImg) {
            deleteFile({ fileId: file.fileId }).then((data) => {
              if (data) {
                state.fileList = state.fileList.filter(
                        (item) => item.fileId !== file.fileId
                );
                state.uploaded = false
              }
            });
            console.log(file.fileId);
          } else {
            state.fileList = state.fileList.filter(
                    (item) => item.fileId !== file.fileId
            );
            state.uploaded = false
          }
        }
      };

      //自定义删除
      const customDelete = () => {
        //fileList里边应该只有一个
        if(state.fileList.length <= 0) return false
        let file = state.fileList[0]
        beforeDelete(file)
      }

      //开启节流的自定义删除
      const throttleCustomDelete = throttle(customDelete, 5000)

      //预览视频
      const previewVideo = () => {
        state.showOverlay = true
        videoRef.value.play()
      }

      //停止预览视频
      const stopPreviewVideo = () => {
        videoRef.value.pause()
        state.showOverlay = false
      }

      const searchFile = () => {
        let param = {
          busiId: props.busiId,
          busiCat: props.busiCat,
          groupId: props.groupId,
        };
        searchFileList(param).then((data) => {
          if (data) {
            data.map((bloc) => {
              let item = {
                fileId: bloc.fileId,
                url: `${process.env.VUE_APP_WATCH_FILE_API}/${bloc.url}`,
              };
              state.fileList.push(item);
              state.videoUrl = item.url;
              state.uploaded = true;
            });
          } else {
            state.fileList = []
          }
        });
      };
      onMounted(() => {
        searchFile();
      });
      return {
        ...toRefs(state),
        videoRef,
        afterRead,
        beforeRead,
        beforeDelete,
        customDelete,
        throttleCustomDelete,
        previewVideo,
        stopPreviewVideo
      };
    },
  };
</script>
<style lang="scss">
.van-field__value{
  width: 100%;
}
  .my-upload {
    .van-uploader__preview {
      width: 100%;
      margin: 0;
    }
    .van-uploader {
      width: 100%;
    }
    .van-uploader__wrapper {
      width: 100%;
    }
    .van-uploader__input-wrapper {
      width: 100%;
    }
    .van-uploader__preview-image {
      width: 100%;
      height: 160px;
    }
  }
</style>
<style lang="scss" scoped>
  .place-msg {
    color: #666666;
    font-size: 12px;
    margin-top: 10px;
  }
  .my-upload {
    width: 100%;
  }
  .preview-box {
    display: flex;
    border-radius: 4px;
    background: #fff;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 160px;
    position: relative
  }
  .upload-box {
    display: flex;
    border-radius: 4px;
    background: #fff;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 160px;
  }
  .upload-icon-box {
    display: flex;
    align-items: center;
    width: 52px;
    height: 52px;
    background: #289fff;
    border-radius: 50%;
    justify-content: center;
  }

  .preview-icon-box {
    display: flex;
    align-items: center;
    width: 46px;
    height: 46px;
    background: rgba(0,0,0,0.3);
    border-radius: 50%;
    justify-content: center;
  }

  .preview-icon-box-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .overlay-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: rgba(0,0,0,0.3);
  }

  .video_box {
    object-fit: fill !important;
    z-index: 999;
    width: 100%;
    max-height: 100%;
    /*height: 100%;*/
    /*position: absolute;*/
    /*left: 0;*/
    /*top: 0;*/
    overflow: hidden;
  }
</style>
